.btn-dark {
  background-color: #000;
  color: #FFF;
  border-color: #000;
  background-image: linear-gradient(to bottom, #626262 0, #000 100%);

}

.btn-dark:hover {
  background-color: #000;
  color: #FFF;
  border-color: #000;
  background-image: linear-gradient(to bottom, #000 0, #626262 100%);
}

.btn-dark:focus,
.btn-dark:active {
  background-color: #000;
  color: #FFF;
  border-color: #000;
  background-image: linear-gradient(to bottom, #626262 0, #000 100%);
}

.btn-dark:disabled {
  background-color: #000;
  color: #FFF;
  border-color: #000;
  background-image: linear-gradient(to bottom, #626262 0, #000 100%);
}

.navbar-brand {
  height: 48px;
  z-index: 999;
}

.label-dashboard {
  padding: .4em .8em .6em;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.btn:hover {
  text-decoration: none;
}

.navbar-light .navbar-nav>li>a:hover,
.navbar-light .navbar-nav>li>a:focus {
  background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);
  text-decoration: none;
}


*:focus {
  outline: none !important;
}


.navbar {
  padding-bottom: 0px;
  padding-top: 0px;
}

.navbar-nav {
  --bs-nav-link-padding-y: 1rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 3rem;
  text-align: left;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 1rem 0;
  /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
  text-align: left
}

/* rtl:end:remove */

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}



h2 {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.bc-purple-premium1 {
  color: #9e89c4;
  border-color: #9e89c4;
  border-width: 3px;
}

.bc-purple-premium2 {
  color: #8a6dc0;
  border-color: #8a6dc0;
  border-width: 3px;
}

.bc-purple-premium3 {
  color: #6f42c1;
  border-color: #6f42c1;
  border-width: 3px;
}

.bc-elevate-premium1 {
  color: #c4ac89;
  border-color: #c4ac89;
  border-width: 3px;
}

.bc-elevate-premium2 {
  color: #c09c6d;
  border-color: #c09c6d;
  border-width: 3px;
}

.bc-elevate-premium3 {
  color: #bc7400;
  border-color: #bc7400;
  border-width: 3px;
}

.bc-premium1 {
  color: #777;
  border-color: #777;
  border-width: 3px;
}

.bc-premium2 {
  color: #000;
  border-color: #000;
  border-width: 3px;
}

.bc-premium3 {
  color: #bc7400;
  border-color: #bc7400;
  border-width: 3px;
}

.bi-check {
  color: green;
}

.bi-x {
  color: red;
}

.bi-info {
  color: blue;
}

.rounded-circle{
  border-radius: 50%;
}

.top-right{
  position: absolute;
  top: 0;
  right: 0;
}

.btn-xs { 
  --bs-btn-padding-y: .05rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .75rem;
}



.first-letter {
  font-size: 35px;
  line-height: 70px;
}

.text-purple {
  color: rgb(111, 15, 116);
}

.icon-rotate-90 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}
